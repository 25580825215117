import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import InstrumentInfo from '@/components/common/InstrumentInfo';
import {
    TickerOrderFormInput,
    TickerOrderFormValues,
    tickerOrderSchema
} from '@/components/form/schema/tickerOrderSchema';
import instrumentConfig from '@/config/instruments';
import { submitLimitOrder, submitMarketOrder } from '@/services/OrderService';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { setActivePair } from '@/state/reducers/marketPairSlice';
import { setActiveTicker } from '@/state/reducers/tickerSlice';
import { TraderMarket, removeSubscription } from '@/state/reducers/traderMarketSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { UseDisclosureReturn, useDisclosure } from '@/utils/hooks/useDisclosure';
import { useInstrument } from '@/utils/hooks/useInstrument';
import useTickerOrderBook from '@/utils/hooks/useTickerOrderBook';
import { getAssetName } from '@/utils/symbolMapping';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { MdCandlestickChart, MdClose, MdList, MdRefresh } from 'react-icons/md';
import TickerPrice, { Highlights } from './TickerPrice';
import TickerQuantityInput from './TickerQuantityInput';
import TickerSlippageInput from './TickerSlippageInput';

interface TickerWidgetProps {
    id: number;
    market: TraderMarket;
    watchListId: string;
    disclosure: UseDisclosureReturn;
    onRemove: (id: number) => void;
}

interface TickerProps extends TickerWidgetProps {
    isPopOut?: boolean;
    sortable?: ReturnType<typeof useSortable>;
}

const TickerWdiget = (props: TickerWidgetProps) => {
    const { id } = props;

    const sortable = useSortable({ id });
    const { setNodeRef, transform, transition } = sortable;

    const style = {
        transform: CSS.Transform.toString(transform ? { ...transform, scaleX: 1, scaleY: 1 } : null),
        transition
    };

    return (
        <div ref={setNodeRef} className="w-full lg:w-auto" style={style}>
            <Ticker {...props} sortable={sortable} />
        </div>
    );
};

export default TickerWdiget;

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    // { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK }
    // { label: 'GTC', value: TimeInForceType.GTC }
];

const orderTypeOptions: { label: string; value: OrderType }[] = [
    { label: 'MKT', value: OrderType.MARKET },
    { label: 'LMT', value: OrderType.LIMIT }
];

const defaultValues: Partial<TickerOrderFormInput> = {
    side: Side.BUY,
    bestPrice: 0,
    quantity: null,
    duration: durationOptions[0],
    orderType: orderTypeOptions[0],
    slippage: '0.00000'
};

const Ticker = (props: TickerProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const { id, watchListId, market, sortable, disclosure, isPopOut = false, onRemove } = props;

    const [baseCcy, quoteCcy] = useMemo(() => market.celer.split('/'), [market]);

    const [activeCcy, setActiveCcy] = useState<string>(baseCcy);
    const showOrderBook = useDisclosure(false);
    const { pip_size, defaultSlippage, ccy2_enabled } = useInstrument(market.celer);
    const { increments, min_order_size, order_decimals } = useCurrency(activeCcy);

    const isCcy2Order = useMemo(() => activeCcy === quoteCcy, [activeCcy, quoteCcy]);
    const currencyAssetName = useMemo(() => getAssetName(activeCcy), [activeCcy]);

    const form = useForm<TickerOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            tickerOrderSchema({
                min_order_size,
                order_decimals,
                currencyOut: activeCcy,
                currencyPair: market.show
            })
        )
    });

    const { reset, watch, register, setValue, getFieldState, handleSubmit, formState } = form;
    const fieldState = getFieldState('quantity', formState);

    const [orderType, quantity] = watch(['orderType', 'quantity']);

    const { bestBid, bestAsk, bidVWAP, askVWAP, spread, vwapSpread } = useTickerOrderBook(market, quantity || 0);

    const instrumentType = useMemo(() => {
        const config = instrumentConfig[market.celer];
        switch (config?.type) {
            case 'FX':
                return 'FX Spot';
            case 'Crypto':
                return 'Crypto CFD';
            case 'Index':
                return 'Index CFD';
            case 'Commodity':
                return 'Comm CFD';
            default:
                return '';
        }
    }, [market.celer]);

    const onSubmit = async (data: TickerOrderFormInput, e) => {
        const dataValidated = data as TickerOrderFormValues;
        if (dataValidated.quantity && credentials) {
            if (data.orderType?.value === OrderType.MARKET) {
                await submitMarketOrder(
                    {
                        spotPrice: data.bestPrice as number,
                        securityId: market.celer,
                        currencyOut: activeCcy,
                        quantity: dataValidated.quantity,
                        timeInForce: dataValidated.duration.value as TimeInForceType,
                        side: dataValidated.side as Side
                    },
                    credentials,
                    currentAccount
                );
            } else if (data.orderType?.value === OrderType.LIMIT) {
                const slippage = dataValidated.slippage
                    ? BigNumber(dataValidated.slippage)
                          .dividedBy(Math.pow(10, pip_size || 0))
                          .toString()
                    : '0';
                if (dataValidated.quantity && credentials) {
                    await submitLimitOrder(
                        {
                            securityId: market.celer,
                            currencyOut: activeCcy,
                            quantity: dataValidated.quantity,
                            limitPrice: dataValidated.bestPrice,
                            timeInForce: dataValidated.duration.value as TimeInForceType,
                            side: dataValidated.side as Side,
                            slippage
                        },
                        credentials,
                        currentAccount
                    );
                }
            }
        }
    };

    const onError = (errors) => console.log(errors);

    useDidUpdate(() => setValue('quantity', null), [activeCcy]);
    // useDidUpdate(() => {
    //     if (credentials) {
    //         const subscriptions = store.getState().marketPair.subscriptions || {};
    //         if (showOrderBook[0]) {
    //             upgradeMarket(credentials, market.celer, dispatch);
    //         } else {
    //             degradeMarket(credentials, market.celer, subscriptions, dispatch);
    //         }
    //     }
    // }, [showOrderBook[0]]);
    useEffect(() => reset({ ...defaultValues, slippage: defaultSlippage }), []);

    return (
        <FormProvider {...form}>
            <div className="relative w-full lg:w-60 bg-brand-background-dark text-neutral-200 rounded-sm border border-neutral-700">
                <div className="absolute top-0.5">
                    <InstrumentInfo
                        market={market}
                        offsetOverride={{
                            mainAxis: 2,
                            crossAxis: -1
                        }}
                    />
                </div>
                <div
                    className="ticker-widget-header touch-manipulation flex justify-between items-center bg-brand-background-dark hover:bg-neutral-600 cursor-grab active:cursor-grabbing pl-5"
                    {...sortable?.attributes}
                    {...sortable?.listeners}>
                    <div className="flex items-center justify-center">
                        <span className="text-sm font-bold">
                            {market.show} - {instrumentType}
                        </span>
                    </div>
                    <div className="flex">
                        <div
                            title="Open In Chart"
                            className={cn('h-full flex items-center p-1', {
                                ['cursor-pointer hover:bg-brand-primary']: !isPopOut,
                                ['cursor-not-allowed text-neutral-600']: isPopOut
                            })}
                            onClick={() => {
                                if (!isPopOut) dispatch(setActivePair(market));
                            }}>
                            <MdCandlestickChart className="w-4 h-4" name="Open In Chart" />
                        </div>
                        <div
                            title="Close Widget"
                            className={cn('h-full flex items-center p-1', {
                                ['cursor-pointer hover:bg-brand-primary']: !isPopOut,
                                ['cursor-not-allowed text-neutral-600']: isPopOut
                            })}
                            onClick={() => {
                                if (!isPopOut) {
                                    onRemove(id);
                                    dispatch(removeSubscription({ id: watchListId, market }));
                                }
                            }}>
                            <MdClose className="w-4 h-4" name="Close Widget" />
                        </div>
                    </div>
                </div>
                <div
                    className={cn('relative flex p-1 gap-1 text-sm border-t border-neutral-700', {
                        'border-b': !showOrderBook[0],
                        'pb-0': showOrderBook[0]
                    })}>
                    <TickerPrice
                        market={market}
                        showOrderBook={showOrderBook}
                        currencyAssetName={currencyAssetName}
                        side={isCcy2Order ? Side.BUY : Side.SELL}
                        bestPrice={bidVWAP.price || bestBid.price}
                        onSubmit={handleSubmit(onSubmit, onError)}
                        alignItems="left"
                        priceType="bid"
                        isCcy2Order>
                        <Highlights
                            ticker={bidVWAP.pricePips || bestBid.pricePips}
                            highlights={bidVWAP.highlights || bestBid.highlights}
                        />
                    </TickerPrice>
                    <div className="absolute w-full h-14 flex justify-center items-center top-0 left-0">
                        <span className="absolute text-xs border border-neutral-700 bg-brand-background-dark p-1 px-2 rounded-full z-[2]">
                            {vwapSpread || spread || '-'}
                        </span>
                    </div>
                    <TickerPrice
                        market={market}
                        showOrderBook={showOrderBook}
                        currencyAssetName={currencyAssetName}
                        bestPrice={askVWAP.price || bestAsk.price}
                        side={isCcy2Order ? Side.SELL : Side.BUY}
                        onSubmit={handleSubmit(onSubmit, onError)}
                        alignItems="right"
                        priceType="ask"
                        isCcy2Order>
                        <Highlights
                            ticker={askVWAP.pricePips || bestAsk.pricePips}
                            highlights={askVWAP.highlights || bestAsk.highlights}
                        />
                    </TickerPrice>
                </div>
                <div className="p-1">
                    <div className="flex">
                        <TickerQuantityInput
                            {...register('quantity')}
                            min={min_order_size}
                            step={increments}
                            active_ccy={activeCcy}
                            instrumentType={instrumentType}
                            currencyAssetName={currencyAssetName}
                            ccy2_enabled={ccy2_enabled}
                            switchCcy={() => setActiveCcy(activeCcy === baseCcy ? quoteCcy : baseCcy)}
                        />
                    </div>
                    {fieldState.error ? (
                        <span className="text-brand-red italic text-xs">{fieldState.error?.message}</span>
                    ) : null}
                    <div className="flex">
                        <div
                            className="flex justify-center items-center px-1.5 border border-neutral-700 cursor-pointer hover:bg-neutral-600"
                            onClick={showOrderBook[1].toggle}>
                            <MdList className="w-5 h-5" />
                        </div>
                        <TickerOrderTypeSwitch />
                        <DurationSwitch />
                        {/* <DurationSwitch options={durationOptions} /> */}
                        <TickerSlippageInput
                            {...register('slippage')}
                            disabled={orderType?.value === OrderType.MARKET}
                            defaultSlippage={defaultSlippage}
                            step={0.1}
                        />
                        <div
                            className="flex items-center justify-center py-1 px-2 text-sm bg-neutral-700 hover:bg-neutral-600 cursor-pointer"
                            onClick={() => {
                                dispatch(setActiveTicker(market));
                                disclosure[1].toggle();
                            }}>
                            Order
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

const TickerOrderTypeSwitch = () => {
    const { watch, setValue } = useFormContext<TickerOrderFormInput>();

    const orderType = watch('orderType');
    return (
        <div
            className="flex-1 min-w-[46px] px-2 text-sm flex items-center justify-between md:justify-center cursor-pointer border border-neutral-700 hover:bg-neutral-600"
            onClick={() => {
                if (orderType?.value === OrderType.MARKET) {
                    setValue('orderType', orderTypeOptions[1]);
                } else {
                    setValue('orderType', orderTypeOptions[0]);
                }
            }}>
            {orderType?.label}
            <MdRefresh className="block md:hidden" />
        </div>
    );
};

const DurationSwitch = () => {
    const { watch, setValue } = useFormContext<TickerOrderFormInput>();

    const duration = watch('duration');
    return (
        <div
            className="flex-1 min-w-[46px] px-2 text-sm flex items-center justify-between md:justify-center cursor-pointer border border-neutral-700 hover:bg-neutral-600"
            onClick={() => {
                if (duration?.value === TimeInForceType.IOC) {
                    setValue('duration', durationOptions[1]);
                } else {
                    setValue('duration', durationOptions[0]);
                }
            }}>
            {TimeInForceType[duration?.value as number]}
            <MdRefresh className="block md:hidden" />
        </div>
    );
};

// interface Option {
//     label: string;
//     value: any;
// }

// interface DurationSwitchProps {
//     options: Option[];
//     selected?: Option;
//     onChange?: (e: Option) => void;
// }

// const DurationSwitch = forwardRef((props: DurationSwitchProps, _ref) => {
//     const { control } = useFormContext<TickerOrderFormInput>();
//     const { options } = props;

//     return (
//         <Controller
//             control={control}
//             name="duration"
//             render={({ field: { onChange, value: selected } }) => (
//                 <Listbox value={selected?.value} onChange={onChange}>
//                     <div className="flex-1 relative w-full">
//                         <Listbox.Button className="relative w-full cursor-pointer bg-brand-background-dark border border-neutral-600 py-1.5 pl-2 pr-10 text-left text-sm">
//                             <span className="block truncate">{selected?.label}</span>
//                             <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
//                                 <MdExpandMore className="h-5 w-5" aria-hidden="true" />
//                             </span>
//                         </Listbox.Button>
//                         <Transition
//                             as={Fragment}
//                             leave="transition ease-in duration-100"
//                             leaveFrom="opacity-100"
//                             leaveTo="opacity-0">
//                             <Listbox.Options className="absolute z-[60] mt-2 max-h-60 w-full overflow-auto rounded-md bg-brand-background-dark border border-neutral-600 py-1 text-sm shadow-lg shadow-neutral-900">
//                                 {options.map((option, optionIdx) => (
//                                     <Listbox.Option
//                                         key={optionIdx}
//                                         className={({ active }) =>
//                                             cn(`relative cursor-pointer select-none py-2 pl-3 pr-4`, {
//                                                 ['bg-brand-primary']: active,
//                                                 'text-brand-primary-light': selected?.value === option.value,
//                                                 'text-neutral-200': active && selected?.value === option.value
//                                             })
//                                         }
//                                         value={option}>
//                                         <span className={cn(`block truncate`)}>{option.label}</span>
//                                     </Listbox.Option>
//                                 ))}
//                             </Listbox.Options>
//                         </Transition>
//                     </div>
//                 </Listbox>
//             )}
//         />
//     );
// });

// DurationSwitch.displayName = 'DurationSwitch';
